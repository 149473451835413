<template>
  <v-container>
    <v-row>
      <v-col cols="7">
        <material-card :color="fnSearchColor(guia.guiaStatus)" full-header>
          <template #heading dense>
            <div class="pa-4 white--text">
              <div class="text-h4 font-weight-light">
                Guía: {{ guia.guiaCodigo }}
              </div>
              <div>ID: {{ guia._id }}</div>
            </div>
          </template>
          <v-card-text>
            <v-timeline align-top dense>
              <v-timeline-item color="pink" small>
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>Creación</strong>
                  </v-col>
                  <v-col>
                    <strong>{{ formatDateUtc(guia.createdAt) }}</strong>
                    <!-- <div class="caption">Mobile App</div> -->
                  </v-col>
                </v-row>
              </v-timeline-item>
              <v-timeline-item
                v-if="guia.quienCarga"
                color="teal lighten-3"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="3" class="mb-2">
                    <strong>Asignado a vehiculo</strong>
                  </v-col>
                  <v-col>
                    <strong>{{ formatDateUtc(guia.quienCarga.fecha) }}</strong>
                    <div class="caption mb-2">
                      Piloto: {{ guia.quienCarga.pilotoNombre }} <br />
                      Placa: {{ guia.quienCarga.pilotoPlaca }}
                    </div>

                    <!-- <v-avatar>
                            <v-img
                              src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown"
                            ></v-img>
                          </v-avatar>
                          <v-avatar>
                            <v-img
                              src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned"
                            ></v-img>
                          </v-avatar>
                          <v-avatar>
                            <v-img
                              src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairMiaWallace&accessoriesType=Sunglasses&hairColor=BlondeGolden&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Surprised&eyebrowType=RaisedExcited&mouthType=Smile&skinColor=Pale"
                            ></v-img>
                          </v-avatar> -->
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!-- /** ENTREGA INFORMACION */ -->
              <v-timeline-item
                v-if="guia.guiaStatus == 'Entregado'"
                :color="fnSearchColor(guia.guiaStatus)"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>{{ guia.guiaStatus }}</strong>
                  </v-col>
                  <v-col>
                    <strong>{{
                      formatDateUtc(guia.quienEntrega.fecha)
                    }}</strong>
                    <div
                      class="caption mb-1"
                      title="fecha de sincronización al servidor"
                    >
                      Sync: {{ formatDateUtc(guia.quienEntrega.syncFecha) }}
                    </div>
                    <div class="caption">
                      Piloto: {{ guia.quienEntrega.pilotoNombre }}
                    </div>
                    <div class="caption mb-2">
                      Placa: {{ guia.quienEntrega.pilotoPlaca }}
                    </div>
                  </v-col>
                  <!-- /** INFORMACION CUANDO RECIBE PERSONA NO TITULAR */ -->
                  <!-- prettier-ignore -->
                  <v-col v-if="guia.entrega.recibeOtro">
                    <strong title="El pedido no fue recibido por el titular de la guía">Recibido por</strong>

                    <div><small>Nombre: </small><strong>{{ guia.entrega.quienRecibeNombre }}</strong></div>
                    <div><small>DPI:</small> <strong>{{ guia.entrega.quienRecibeDPI }}</strong></div>
                    
                    <div><small>Tel:</small> <strong>{{ guia.entrega.quienRecibeTelefono }}</strong></div>
                    <div><small>Parentesco:</small> <br><strong>{{ guia.entrega.quienRecibeParentesco }}</strong></div>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!-- /** ENTREGA INFORMACION */ -->
              <!-- /** DEVOLUCION INFORMACION */ -->
              <v-timeline-item
                v-if="guia.guiaStatus == 'Con devolución'"
                :color="fnSearchColor(guia.guiaStatus)"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>{{ guia.guiaStatus }}</strong>
                  </v-col>
                  <v-col>
                    <strong>{{ getDate(guia.detalleDevolucion.date) }}</strong>
                    <div class="caption mt-2">
                      Piloto: {{ guia.detalleDevolucion.pilotoNombre }}
                    </div>
                    <div class="caption mb-2">
                      Placa: {{ guia.detalleDevolucion.pilotoPlaca }}
                    </div>
                    <div class="caption mb-2">
                      Motivo: {{ guia.detalleDevolucion.motivo }}
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!-- /** DEVOLUCION INFORMACION */ -->
            </v-timeline>
          </v-card-text>
        </material-card>
        <!-- FOTOGRAFIA, FIRMA Y MAPA -->
        <v-row v-if="guia.guiaStatus == 'Entregado'" class="pt-2">
          <v-col cols="6" sm="6">
            <v-card>
              <v-card-text>
                <div class="subheading">Foto de entrega</div>
                <zoom-on-hover
                  v-if="guia.entrega.img_url"
                  :scale="5"
                  :img-normal="guia.entrega.img_url"
                  :img-zoom="guia.entrega.img_url"
                ></zoom-on-hover>

                <span v-else>No data info.</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" sm="6">
            <v-card>
              <v-card-text>
                <div class="subheading">Firma</div>
                <v-img
                  v-if="guia.entrega.firma_url"
                  contain
                  :src="guia.entrega.firma_url"
                ></v-img>
                <span v-else>No data info.</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- FOTOGRAFIA, FIRMA -->
      </v-col>

      <!-- /** <DETALLES DEL PEDIDO> */ -->
      <v-col>
        <v-container>
          <v-card v-if="guia.guiaReceptor">
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>
                        <span
                          :class="
                            fnSearchColor(guia.guiaStatus) +
                            '--text text--darken-1'
                          "
                        >
                          {{ guia.guiaStatus }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td style="word-break: normal">Cliente:</td>
                      <td>
                        <v-card-subtitle class="font-weight-black">{{
                          guia.guiaReceptor.guiaReceptorNombre
                        }}</v-card-subtitle>
                      </td>
                    </tr>
                    <tr>
                      <td>Dirección:</td>
                      <td>
                        <v-card-subtitle class="font-weight-black"
                          >{{ guia.guiaReceptor.guiaReceptorDireccion }},
                          {{ guia.guiaReceptor.guiaReceptorDepartamento }},
                          {{
                            guia.guiaReceptor.guiaReceptorMunicipio
                          }}.</v-card-subtitle
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>Teléfono:</td>
                      <td>
                        <v-card-subtitle class="font-weight-black">{{
                          guia.guiaReceptor.guiaReceptorTelefono
                        }}</v-card-subtitle>
                      </td>
                    </tr>
                    <tr>
                      <td>Detalle:</td>
                      <td>
                        <v-card-subtitle
                          v-if="
                            !guia.guiaDescripcion || guia.guiaDescripcion == ''
                          "
                          class="font-weight-black"
                          >N/A</v-card-subtitle
                        >
                        <v-list-item
                          v-else
                          two-line
                          v-for="(detalle, index) in guia.guiaDescripcion"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              >bulto {{ index + 1 }}</v-list-item-title
                            >
                            <v-list-item-subtitle
                              ><div v-if="detalle.cantidad">
                                Cantidad: {{ detalle.cantidad }}
                              </div>
                              <div v-if="detalle.tipo">
                                Empaque: {{ detalle.tipo }}
                              </div>
                              <div v-if="detalle.peso">
                                Peso: {{ detalle.peso }}
                              </div>
                              <div v-if="detalle.dimension">
                                Dimensión: {{ detalle.dimension }}
                              </div>
                              <div v-if="detalle.descripcion">
                                Descripción: {{ detalle.descripcion }}
                              </div></v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td style="word-break: normal">Valor del pedido:</td>
                      <td>
                        <v-card-subtitle class="font-weight-black"
                          >Q{{
                            guia.opcionPagoMonto ? guia.opcionPagoMonto : '0.00'
                          }}</v-card-subtitle
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>Forma de pago:</td>
                      <td>
                        <v-card-subtitle
                          :class="[
                            guia.opcionPago == 'Al crédito'
                              ? 'orange--text'
                              : 'teal--text',
                            'font-weight-black darken-4',
                          ]"
                          >{{ guia.opcionPago }}</v-card-subtitle
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-text v-if="guia.entrega">
              <div class="subheading">Punto de entrega</div>
              <!-- {{ guia.entrega.coordenadas.coordinates }} -->
              <getMapa :market="guia.entrega.coordenadas.coordinates"></getMapa>
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
      <!-- /** <DETALLES DEL PEDIDO> */ -->
    </v-row>
  </v-container>
</template>
<script>
import getMapa from '../vistasresumenes/getMapa.vue';

export default {
  name: 'modalInformacionDeGuiaCuerpo',
  props: ['guia'],
  components: {
    getMapa,
  },
  data() {
    return {
      market: { coordenadas: { lat: 15.0032599, lng: -90.4136375 } },
    };
  },
  methods: {
    formatDateUtc(fecha) {
      return moment(fecha).utc().format('DD-MM-YYYY hh:mm a');
    },
    getDate(fecha) {
      return moment(fecha).format('DD-MM-YYYY hh:mm a');
    },
    fnSearchColor(estado) {
      console.log(estado);
      if (estado) {
        let store_estado = this.$store.state.estados.filter(
          (store_estado) => store_estado.db_label == estado
        );
        // console.log(rol[0]);
        return store_estado[0].color;
      } else {
        return 'info';
      }
    },
    fnGetVisitaImagen(dir) {
      return process.env.VUE_APP_FEATHERSJS_SERVER + dir;
    },
  },
};
</script>
